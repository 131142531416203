import { Injectable } from "@angular/core";
import { Category, CATEGORIES_de, CATEGORIES_en } from "../models/category";
import { BehaviorSubject } from "rxjs";
import { LANGUAGES_en, SimpleLanguage, LANGUAGES_de } from "../models/language";
import { environment } from "../../environments/environment"

@Injectable({
    providedIn: 'root'
})
export class TranslationService {
    private categories = new BehaviorSubject<Category[]>([] as Category[]);
    private langs = new BehaviorSubject<SimpleLanguage[]>([] as SimpleLanguage[])
    private currentLang = new BehaviorSubject<SimpleLanguage>(LANGUAGES_en[0])
    categoriesLangMap: Map<string, Category[]> = new Map()
    languageLangMap: Map<string, SimpleLanguage[]> = new Map()

    constructor() {
        this.languageLangMap = new Map().set("en", LANGUAGES_en).set("de", LANGUAGES_de)
        this.categoriesLangMap.set("en", CATEGORIES_en)
        this.categoriesLangMap.set("de", CATEGORIES_de)
        this.initialize(environment.DEFAULT_LANG)
    }

    initialize(lang: string) {
        console.log(this.languageLangMap.get(lang))
        this.setCategories(lang)
        this.setLanguage(lang)
    }

    setCategories(lang: string) {
        if (this.categoriesLangMap.has(lang)) {
            this.categories.next(this.categoriesLangMap.get(lang))
        } else {
            //Default to english
            //Or decide not to do anything
            this.categories.next(this.categoriesLangMap.get("en"))
        }
    }

    setLanguage(lang: string) {
        if (this.languageLangMap.has(lang)) {
            this.langs.next(this.languageLangMap.get(lang))
            let l = this.languageLangMap.get(lang).filter(e => e.id == lang)[0]
            console.log(l)
            this.currentLang.next(l)
        } else {
            //Default to english
            this.langs.next(this.languageLangMap.get("en"))
            let l = this.languageLangMap.get(lang).filter(e => e.id == "en")[0]
            console.log(l)
            this.currentLang.next(l)
        }
    }

    languageChanged(lang: string) {
        this.setCategories(lang)
        this.setLanguage(lang)
    }

    getCategories() {
        return this.categories
    }

    getCurrentLang() {
        return this.currentLang
    }

    getLanguages() {
        return this.langs
    }

    saveLanguage(lang: string) {
        localStorage.setItem("language", lang)
    }

    getLanguage() {
        return localStorage.getItem("language")
    }
}