import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { CATEGORIES_de } from '../../models/category';
import { Contact } from '../../models/contact.model';
import { ApiService } from '../../services/api.service';
import { ScriptService } from '../../services/script.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-id-card',
  templateUrl: './id-card.component.html',
  styleUrls: ['./id-card.component.scss']
})
export class IdCardComponent implements OnInit {

  contact = new Contact()
  categories = CATEGORIES_de
  isLoading = false;
  buttonMsg = "Senden"
  countries = ["Deutschland", "Österreich", "Schweiz"]
  services = ["Führerschein", "Personalausweis"]
  country = "Deutschland"
  service = "Führerschein"

  constructor(private apiService: ApiService, private scriptService: ScriptService) {
  }

  ngOnInit() {
    this.buttonMsg = "Senden"
  }

  submit() {
    this.buttonMsg = "Wird geladen...."
    this.contact.subject = "Country: "+ this.country + ", Service: " + this.service
    this.apiService.contact(this.contact)
      .subscribe((data: any) => {
        this.isLoading = true;
        Swal.fire({
          title: "Vielen Dank!",
          text: "Vielen Dank! Ihre Nachricht ist angekommen. Wir werden uns so schnell wie möglich bei Ihnen melden.",
          type: 'success',
          confirmButtonText: 'OK',
        })
        this.contact = new Contact();
        this.isLoading = false;
        this.buttonMsg = "Senden"
      },
        (err: HttpErrorResponse) => {
          this.isLoading = false;
          Swal.fire({
            title: "Error!",
            text: "Formular kann nicht gesendet werden. Stellen Sie sicher, dass Sie mit dem Internet verbunden sind",
            type: 'warning',
            confirmButtonText: 'OK!',
          })
          this.buttonMsg = "Senden"
        });
  }

}
