import {RouterModule, Routes} from '@angular/router'
import { HomeComponent } from './components/home/home.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { AboutComponent } from './components/about/about.component';
import { ContactComponent } from './components/contact/contact.component';
import { FaqComponent } from './components/faq/faq.component';
import { CategoriesComponent } from './components/categories/categories.component';
import { CategoryComponent } from './components/category/category.component';
import { TestimonialsComponent } from './components/testimonials/testimonials.component';
import { IdCardComponent } from './components/id-card/id-card.component';
import { LicenseComponent } from './components/license/license.component';

export const routes: Routes = [
    { path: '', component: HomeComponent , data: { title: 'Führerschein-Ausweis - Es war noch nie einfacher, einen Führerschein schnell und ohne Test online zu kaufen!'}},
    { path: 'ausweis', component: IdCardComponent, data: {title: 'Führerschein-Ausweis - Führerscheinklassen'}},
    { path: 'fuhrerschein', component: LicenseComponent, data: {title: 'Führerschein-Ausweis - Führerscheinklassen'}},
    // { path: 'kategorie/:id', component: CategoryComponent, data: {title: 'Titles.2'}},
    { path: 'haufig-gestellte-fragen', component: FaqComponent, data: {title: 'Führerschein-Ausweis - Häufig gestellte Fragen'}},
    // { path: '404', component: NotFoundComponent, data: {title: 'Titles.4'}},
    { path: 'kontakt', component: ContactComponent, data: {title: 'Führerschein-Ausweis - Kontaktiere uns'}},
    { path: 'uber', component: AboutComponent, data: {title: 'Führerschein-Ausweis - Über uns'}},
    { path: 'zeugnisse', component: TestimonialsComponent, data: {title: 'Führerschein-Ausweis - Zeugnisse'}},
    { path: '**', redirectTo: '/' }
]
export const routing = RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' });