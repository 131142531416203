import { AppComponent } from './app.component';
import { AppModule } from './app.module';
import { NgModule } from '@angular/core';
import { BrowserModule, TransferState, BrowserTransferStateModule } from '@angular/platform-browser';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { HttpClient } from '@angular/common/http';
import { TranslateBrowserLoader } from './services/translate-browser-loader.service';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { LocalizeRouterModule, LocalizeParser, LocalizeRouterSettings } from 'localize-router';
import {LocalizeRouterHttpLoader} from 'localize-router-http-loader';
import { routes } from './app.routing';

export function exportTranslateStaticLoader(http: HttpClient, transferState: TransferState) {
  return new TranslateBrowserLoader(transferState, http);
}

@NgModule({
  bootstrap: [
    AppComponent
  ],

  imports: [
    BrowserModule.withServerTransition({
      appId: 'app-root'
    }),
    AppModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  ]
})
export class AppBrowserModule { }
