import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { Contact } from '../../models/contact.model';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor(private http: HttpClient, private api: ApiService) { }

  ngOnInit() {
  }

  onNavigate() {
    window.open("https://api.whatsapp.com/send?phone=491639302823", "_blank");
    let contact = new Contact()
    this.http.get<{ip:string}>('https://jsonip.com')
    .subscribe( data => {
      console.log('the data', data.ip);
      this.http.get<{
        ip: string,
        country: string,
        city: string,
        org: string
      }>("https://ipinfo.io/"+data.ip+"?token=2824b78dc815fe").subscribe( geo => {
        console.log("the geo data", geo)
        contact.name = "New WhatsApp Click Data"
        contact.email = "None"
        contact.phone = "None"
        contact.subject = "WhatsApp button click tracking data"
        contact.message = "IP address: " + geo.ip + ", Country: "+ geo.country + ", City: " + geo.city + ", Organization: " + geo.org
        this.api.contact(contact).subscribe(lol => console.log(lol))
      })
    })
  }
}
