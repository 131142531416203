import { NgtUniversalModule } from '@ng-toolkit/universal';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TransferHttpModule, TransferHttpService } from '@gorniv/ngx-universal';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { NO_ERRORS_SCHEMA } from '@angular/core';

import { AppComponent } from './app.component';
import { routing, routes } from './app.routing';
import { ApiService } from './services/api.service';
import { ScriptService } from './services/script.service';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { HomeComponent } from './components/home/home.component';
import { CategoriesComponent } from './components/categories/categories.component';
import { CategoryComponent } from './components/category/category.component';
import { FaqComponent } from './components/faq/faq.component';
import { ContactComponent } from './components/contact/contact.component';
import { AboutComponent } from './components/about/about.component';
import { PrivacyComponent } from './components/privacy/privacy.component';
import { TermsComponent } from './components/terms/terms.component';
import { TestimonialsComponent } from './components/testimonials/testimonials.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { TranslationService } from './services/translation.service';
import { SimpleFormComponent } from './components/simple-form/simple-form.component';
import { LicenseComponent } from './components/license/license.component';
import { IdCardComponent } from './components/id-card/id-card.component';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  schemas: [NO_ERRORS_SCHEMA],
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    CategoriesComponent,
    CategoryComponent,
    FaqComponent,
    ContactComponent,
    AboutComponent,
    PrivacyComponent,
    TermsComponent,
    TestimonialsComponent,
    NotFoundComponent,
    SimpleFormComponent,
    LicenseComponent,
    IdCardComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'app-root' }),
  CommonModule,
  NgtUniversalModule,
  TransferHttpCacheModule,
  TransferHttpModule,
  HttpClientModule,
  FormsModule,
  BrowserAnimationsModule,
  BrowserAnimationsModule,
  HttpClientModule,
  routing
  ],
  providers: [
    ApiService,
    ScriptService,
    TranslationService
  ]
})
export class AppModule { }
